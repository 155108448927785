.deposit__terms {
  display: flex;
  flex-direction: column;
}

.deposit__terms--minus {
  margin-top: 15px;
}

.deposit__button {
  margin-top: 28px;
}

.deposit__asterisk {
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 13px;
  font-family: SimSun, sans-serif;
  line-height: 1;
}
