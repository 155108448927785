.wrapper-map-picker {
    width: 100%;
    margin-top: 30px;
}

.wrapper-map-picker .leaflet-control-container {
    width: 100%;
    height: 485px;
}

.map-search-results {
    position: absolute;
    z-index: 9999;
    background-color: #fff;
    width: 100%;
    border: 1px solid #f5f5f5;
    max-height: 485px;
    overflow: auto;
    box-shadow: 2px 6px 10px #f5f5f5;
}

.map-search-results-item {
    padding: 5px 5px;
    border-bottom: 1px solid #f5f5f5;
    cursor: pointer;
}

.leaflet-div-icon {
    border: none;
    /*width: 30px;*/
    /*height: 30px;*/
    background: transparent;
}

.work-hours {
    padding: 15px;
}

.work-hours__top-border {
    border-bottom: 1px solid #fcc000;
}

.work-hours__top-border:last-child {
    border-bottom: none;
}

.work-hours .no-margin {
    margin-bottom: 0;
}