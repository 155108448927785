.atm-office {
  margin-bottom: 10px;
  position: relative;
}

.atm-office__position {
  position: absolute;
  right: 5px;
  z-index: 3;
}

.atm-office__select {
  width: 250px;
}
