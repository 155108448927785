.cms {
  .ant-layout-header {
    padding: 0 25px;
    box-shadow: inset 0px -1px 0px #ededed, inset -1px 0px 0px #ededed;

    .logo {
      padding-right: 25px;
      box-shadow: inset 0px -1px 0px #ededed, inset -1px 0px 0px #ededed;
    }
  }

  .ant-layout-content {
    position: relative;
    height: calc(100vh - 64px);
    overflow: auto;

    > .ant-spin {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }

  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    margin: 0;
  }

  .ant-menu.sticky-top {
    height: 100%;
    overflow-y: scroll;
  }

  .ant-menu-item {
    display: flex;
    align-items: center;
  }

  .ant-layout-sider-collapsed .ant-menu-item {
    justify-content: center;
    padding: 0 !important;

    .anticon {
      min-width: 40px;
      background-color: transparent !important;
    }
  }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .ant-menu-title-content span + span {
      position: absolute;
    }
  }

  .ant-menu-submenu {
    .ant-menu-item {
      padding-left: 24px !important;
    }
  }

  .ant-menu-submenu-title {
    font-weight: 600;
  }

  .ant-layout-sider {
    position: sticky;
    top: 0;
    max-height: 100vh;
  }

  .ant-layout-sider-trigger {
    border-right: 1px solid #eef1f2;
  }

  .ant-menu-inline .ant-menu-item,
  .ant-menu-vertical .ant-menu-item {
    .anticon {
      stroke: #000;
      fill: #000;
      color: #000;
    }
  }
  .ant-menu-inline .ant-menu-item::after {
    border-width: 2px;
    border-color: #fff;
  }

  .ant-menu-submenu-open {
    background-color: transparent;

    li.ant-menu-item {
      margin: 0;
      height: 32px;
      border-top: 1px solid #eef1f2;

      &:last-child {
        border-bottom: 1px solid #eef1f2;
      }
    }

    .ant-menu-item-selected {
      a {
        color: #000;
      }
    }
  }

  .ant-menu-inline .ant-menu-item-selected,
  .ant-menu-inline .ant-menu-item-selected,
  .ant-menu-vertical .ant-menu-item-selected,
  .ant-menu-vertical .ant-menu-item-selected {
    span {
      color: #222;
    }
    .anticon {
      stroke: #222;
      fill: #222;
      color: #222;
    }
  }

  .ant-menu-inline .ant-menu-item.ant-menu-item-active,
  .ant-menu-inline .ant-menu-item.ant-menu-item-active,
  .ant-menu-vertical .ant-menu-item.ant-menu-item-active,
  .ant-menu-vertical .ant-menu-item.ant-menu-item-active {
    span {
      color: #222;
    }
  }

  .ant-table-thead > tr > th {
    background-color: #fff;
  }

  .ant-table-wrapper {
    background-color: #fff;
    border: 1px solid #eef1f2;
    border-radius: 4px;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.09);

    .ant-table {
      border-bottom: 1px solid #eef1f2;
    }

    .ant-table-pagination {
      padding: 0 24px;
    }
  }

  .ant-table {
    overflow: hidden;
  }

  .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
  }
}

.custom-tabs {
  margin: 0 -10px;
  margin-top: -10px;
  margin-bottom: 10px;
  background-color: #fff;
  max-width: 100%;

  &--hide-content .ant-tabs-content {
    display: none;
  }

  .ant-tabs-bar {
    margin: 0;
  }

  .ant-tabs-tab:first-child {
    margin-left: 16px;
  }

  .ant-tabs-content {
    margin: 0 10px 10px;
    width: calc(100% - 20px);
  }

  .ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane {
    width: calc(100% + 20px);
  }
}

.sticky-top {
  position: sticky;
  top: 0;
}

.sticky-bottom {
  position: sticky;
  bottom: 48px;
}

.no-image {
  max-height: 200px;
  object-fit: cover;
}

.app-fallback {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.full-width {
  width: 100%;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: unset;
}

.ant-upload.ant-upload-select-picture-card {
  width: 50px;
  height: 50px;
}

.drag-tree {
  &__components {
    max-width: 262px;

    + .ant-col {
      flex: 1;
    }
  }
  &__node {
    border-bottom: 1px solid #f1f1f1;

    &:not(:first-child) {
      padding-bottom: 0;

      img {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }

    img {
      object-fit: contain;
    }

    .ant-tree-node-content-wrapper {
      position: relative;

      .ant-tree-iconEle {
        position: absolute;
        right: 0;
        pointer-events: none;
        width: auto;
        padding-right: 4px;

        .anticon-drag {
          opacity: 0.35;
          transition: opacity 350ms;
        }
      }

      .ant-tree-title {
        display: flex;
        height: 100%;
        z-index: 2;

        &,
        > div {
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          padding-right: 10px;
        }
      }
    }

    .disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    &:hover .ant-tree-iconEle .anticon-drag {
      opacity: 1;
    }
  }

  .ant-tree-node-content-wrapper {
    width: calc(100% - 24px);
  }

  .ant-tree-show-line {
    .ant-tree-indent-unit::before {
      bottom: 0;
    }

    .ant-tree-switcher {
      display: none;
    }
  }

  .ant-row {
    flex-wrap: nowrap;

    .ant-col {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.full-screen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  padding: 8px;
  z-index: 11;
  background: #fff;
  overflow: auto;
}

.drop-tree {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border: 1px dashed;
  border-color: rgba(232, 232, 232, 0.8);
  padding: 10px;
  height: calc(100% - 48px);
  transition: border-color 350ms;

  &__tabs {
    .ant-tabs-tab,
    .ant-tabs-nav-add {
      border-top: none !important;
    }

    .ant-tabs-tab {
      .ant-row {
        flex-wrap: nowrap;

        > .ant-col:first-child {
          max-width: 130px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          .ant-input {
            min-width: 150px;
          }
        }
      }
    }
  }

  > .ant-tabs {
    width: 100%;
    height: 100%;
  }

  &:hover {
    border-color: #c9cccf;
  }

  .is-over .drop-tree__node-edit {
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      bottom: 2px;
      height: 2px;
      background-color: #fcc000;
    }

    &::after {
      content: '';
      position: absolute;
      width: 8px;
      bottom: 0;
      left: 0;
      height: 8px;
      border-radius: 100px;
      border: 2px solid #fcc000;
      background-color: white;
      margin-left: -4px;
      transform: translate3d(0, 1px, 0);
    }
  }

  &__node {
    &.overflow-hidden .ant-tree-node-content-wrapper {
      overflow: hidden;
    }

    .ant-tree-draggable-icon {
      &::before {
        content: '';
        position: absolute;
        background: darken(#d9d9d9, 25%);
        height: calc(100% - 40px);
        width: 1px;
        top: 40px;
        margin-left: -4px;
        margin-right: 7px;
      }
    }
    .anticon-delete {
      position: absolute;
      top: 0;
      min-height: 35px;
      display: flex;
      align-items: center;
      right: 8px;
      opacity: 0.35;
      z-index: 3;
      transition: opacity 350ms;
    }

    &:hover .anticon-delete {
      opacity: 1;
    }

    &-edit {
      position: relative;
      display: flex;
      flex-direction: column;

      &__col.ant-form-item {
        height: auto !important;
      }

      > span {
        pointer-events: none;
        padding: 5px;

        img {
          padding-top: 32px;
          max-height: 200px;
          object-fit: contain;
          text-align: left;
          width: auto;
        }
      }

      .ant-upload.ant-upload-select-picture-card {
        margin-top: 8px;
      }

      .ant-dropdown-trigger {
        border-radius: 100px;
        width: 32px;
        padding: 0;
      }

      &__col {
        position: absolute;
        right: 15px;
        width: 100px;
        top: 1px;
        height: 30px;
        text-align: right;
        display: flex;
        align-items: center;
        z-index: 3;

        .ant-dropdown-trigger {
          padding: 0 8px;
          width: auto;
        }
      }

      &__required {
        position: absolute;
        right: 80px;
        width: 120px;
        top: 1px;
        height: 30px;
        z-index: 2;
      }

      &__dependency {
        position: absolute;
        right: 200px;
        width: 40px;
        top: 1px;
        height: 30px;
        z-index: 4;
      }

      &__zIndex {
        position: absolute;
        right: 115px;
        width: 60px;
        top: 1px;
        height: 30px;
        text-align: right;
        display: flex;
        align-items: center;

        .ant-dropdown-trigger {
          padding: 0 8px;
          width: auto;
        }
      }
      > div:not(.hide-options) {
        padding: 5px 0 2.5px;

        &:not(:first-child) {
          padding-top: 0;
        }
      }

      > .hide-options {
        .ant-form {
          background-color: transparent;
        }
      }

      .anticon-minus-circle {
        color: #f54d4f;
      }

      .ant-form {
        background: #fff;
        padding: 0 5px;
        width: calc(100% - 10px);

        > &-item {
          display: flex;
          margin: 0;

          &-control-wrapper {
            flex: 1;
          }
        }
      }

      span > .ant-row {
        width: calc(100% - 13px);
      }
    }

    > .ant-row:not(.hide) {
      height: 100%;
    }

    .ant-upload img {
      height: 100%;
      max-height: 100px;
      object-fit: cover;
    }

    &.has-children {
      .ant-tree-switcher {
        margin-left: 8px;
      }
    }

    .ant-tree-switcher {
      display: flex;
      align-items: center;
      width: auto;
      height: 34px;

      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        font-size: 12px;
      }

      &-line-icon {
        display: none;
      }
    }

    .ant-upload-list-text {
      .anticon-delete {
        min-height: 20px;
      }
    }
  }

  &.drop-tree--hide-switcher .ant-tree {
    &-node-content-wrapper {
      margin-left: 8px;
    }

    .ant-tree-switcher:empty {
      width: auto;
    }
  }

  .ant-tree {
    &-node-content-wrapper {
      width: calc(100% - 24px);
    }

    > li {
      padding: 0;
    }
  }

  .ant-tabs-content,
  .ant-tabs-tabpane,
  .ant-tree-list,
  .ant-tree-list-holder {
    height: 100%;
  }

  .ant-tree-list-holder > div {
    height: 100%;
  }

  .ant-tree-list-holder-inner {
    flex-direction: row !important;
    flex-wrap: wrap;
  }

  .ant-tabs-nav-add .ant-row {
    flex-wrap: nowrap;
  }

  .ant-tree-node-content-wrapper {
    width: 100%;
    //overflow: hidden;
  }
}

.drag-tree,
.drop-tree {
  .ant-tree,
  .ant-tree-treenode {
    height: 100%;
    width: 100%;
  }

  .ant-tree-treenode {
    display: flex;

    .ant-tree-draggable-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 34px;
      min-width: 34px;
    }

    &:last-child:first-child {
      padding: 0;
    }
  }

  .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: #fff9f2;
  }
}

.icon-select .ant-dropdown-menu {
  display: flex;
  flex-wrap: wrap;
  max-width: 253.5px;

  .ant-dropdown-menu-item {
    border: 1px solid transparent;

    &.selected {
      border-color: #fcc000;
    }
  }
}

.col-select .ant-dropdown-menu-item {
  text-align: center;
}

.float-left {
  float: left;
}

.no-wrap {
  white-space: nowrap;
}

.flex {
  display: flex;
}

.flex-items-center {
  align-items: center;
}

.flex-no-wrap {
  @extend .flex;
  align-items: center;
  flex-wrap: nowrap;
}

.ant-radio-wrapper {
  @extend .flex;
  align-items: center;
}

.ant-card-cover img {
  object-fit: cover;
}

.hide {
  height: 0;
  margin: 0 !important;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.background-white {
  background-color: #fff;
}

.business-switch {
  position: absolute;
  right: 20px;
  top: 0;
}

.ant-tooltip img {
  max-width: 100%;
}

body > tr {
  z-index: 9999;
}

.ant-tree .ant-tree-treenode {
  &,
  .ant-tree-node-content-wrapper {
    width: 100%;
  }
}

.ant-tabs-tab button .anticon {
  margin: unset;
}

.button-dir {
  display: flex;
  align-items: center;
  line-height: 0;
  border: none;
  outline: none;
  box-shadow: none;
  font-size: 24px;
}
.anticon .anticon-right-circle {
  height: 2em;
}

.ant-select-dropdown {
  z-index: 9999;
}

.ant-form-item-row {
  width: 100%;
}
