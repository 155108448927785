.article-menu {
  max-height: 80vh;
  overflow: auto;
  margin-bottom: 10px;
}

.date-picker-form-item {
  margin-bottom: 0;
}

.checkbox-group-tags {
  display: flex;
  flex-direction: column;
}
.checkbox-group-tags .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
.checkbox-group-tags .ant-checkbox-wrapper.pid {
  margin-left: 8px;
}

.video-block {
  text-align: center;
  height: 420px;
  max-height: 40vh;
}
.video-block .hide {
  display: none;
}
.video-preview {
  height: 100%;
}
.video-block video {
  height: 100%;
  margin-bottom: 10px;
}
.video-preview {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.schedule-switcher {
  display: flex;
  align-items: center;
  margin: 10px 0 5px;
}
.schedule-switcher > span {
  padding-left: 5px;
}

.sortable-grid {
  display: flex;
  flex-wrap: wrap;
}

.sortable-grid .sortable-item {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: 150px;
  margin: 2px;
  position: relative;
  justify-content: center;
  cursor: grab;
}

.sortable-grid .sortable-item img {
  overflow: hidden;
  padding: 2px;
}

.image-actions {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 10px;
  width: 84px;
}

.image-actions button {
  margin: 0 2px;
}

.image-desc-options button:last-child {
  margin-left: auto;
}

.background-preview {
  height: auto;
  max-width: 100%;
  max-height: 150px;
}
